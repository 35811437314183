import { useQuery, useQueryClient } from 'react-query';
import { Button, CustomPopover } from '../../../components/atoms';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { getSellerDetails, verifySeller } from '../../../network/services/admin/users';
import { ActionCON, extraButtonStyles, OrderDetailsContent } from '../../style';
import { useRecoilState } from 'recoil';
import { sellerActionPopover } from '../../../store/atoms/sellerAtom';
import { viewSellerActionModal } from '../../../store/atoms/modalAtoms';
import { Action, BrokenEyeIcon } from '../../../assets/Icons';
import { Modal } from '../../../components/molecules';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import React, { useState } from 'react'; // Added useState
import { COLORS } from '../../../theme/color';

export const VerifySellerAction = ({ id }: any) => {
  const [value, setValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false); // New state for confirmation modal
  const [rejectReason, setRejectReason] = useState(''); // State to store rejection reason
  const [isConfirmRejectModalOpen, setIsConfirmRejectModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const handleOpen = (event: any) => {
    if (value != null) {
      setValue(null);
    } else {
      setValue(event.currentTarget);
    }
  };

  const { data, refetch } = useQuery(['admin_Seller_details', { id: id }], getSellerDetails, {
    enabled: false // Prevents the query from running initially
  });
  const sellerDetails = data?.data || {};
  const { mutateAsync, isLoading } = useMutationHook(verifySeller, 'VERIFY_SELLER');

  const handleOpenModal = () => {
    setIsOpen(true);
    refetch();
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleVerifyClick = () => {
    // Open the confirmation modal
    setIsConfirmModalOpen(true);
  };
  const handleRejectClick = () => {
    setIsConfirmRejectModalOpen(true);
  };

  const handleConfirmClose = () => {
    // Close the confirmation modal without action
    setIsConfirmModalOpen(false);
  };

  const handleConfirmRejectClose = () => {
    setIsConfirmRejectModalOpen(false);
  };
  const handleConfirmVerify = async () => {
    try {
      const response = await mutateAsync({ id: id, status: 'true', reason: 'Approved' });

      if (response?.data?.status === 'success') {
        queryClient.invalidateQueries('admin_sellers');
        setIsConfirmModalOpen(false); // Close confirmation modal after success
        setIsOpen(false); // Close main modal after success
      }
    } catch (error: any) {}
  };

  const handleConfirmReject = async () => {
    if (!rejectReason.trim()) return; // Ensure there's a reason provided

    try {
      const response = await mutateAsync({ id: id, status: 'false', reason: rejectReason });

      if (response?.data?.status === 'success') {
        queryClient.invalidateQueries('admin_sellers');
        setIsConfirmRejectModalOpen(false); // Close confirmation modal after success
        setIsOpen(false); // Close main modal after success
      }
    } catch (error: any) {
      // Handle error
    }
  };

  const options = [
    {
      label: 'Verify Seller',
      value: 'verify seller',
      Icon: BrokenEyeIcon,
      handleAction: handleOpenModal
    }
  ];

  return (
    <ActionCON aria-describedby="seller-actions" onClick={event => handleOpen(event)}>
      <Action />
      <CustomPopover item={id} id="seller-actions" open={value} options={options} />

      {isOpen && (
        <Modal maxWidth="800px" onModalClose={handleModalClose}>
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', alignItems: 'center' }}>
            <h2>{sellerDetails?.business_name}</h2>
            <div style={{ display: 'flex', gap: '5px' }}>
              <Button
                isLoading={isLoading}
                onClick={handleRejectClick}
                extraStyles={{
                  color: `${COLORS.MainRed}`,
                  backgroundColor: '#F5DDDD',
                  borderRadius: '12px',
                  height: '31px',
                  width: '102px',
                  fontSize: '14px',
                  fontWeight: '400'
                }}>
                Reject
              </Button>
              <Button isLoading={isLoading} onClick={handleVerifyClick} extraStyles={extraButtonStyles}>
                Verify
              </Button>
            </div>
          </div>
          <p style={{ width: '70%', marginBottom: '10px' }}>{sellerDetails?.businessDescription}</p>
          <div style={{ display: 'grid', gap: '15px' }}>
            <OrderDetailsContent>
              <p className="label">Id</p>
              <p className="value">{sellerDetails?.vendorId || 'N/A'}</p>
            </OrderDetailsContent>
            <OrderDetailsContent>
              <p className="label">Email</p>
              <p className="value">{sellerDetails?.email || 'N/A'}</p>
            </OrderDetailsContent>
            <OrderDetailsContent>
              <p className="label">Phone Number</p>
              <p className="value">{sellerDetails?.phoneNumber || 'N/A'}</p>
            </OrderDetailsContent>
            <OrderDetailsContent>
              <p className="label">Address</p>
              <p className="value">{sellerDetails?.business_address || 'N/A'}</p>
            </OrderDetailsContent>
            <OrderDetailsContent>
              <p className="label">Category</p>
              <p className="value">{sellerDetails?.category || 'N/A'}</p>
            </OrderDetailsContent>
            <OrderDetailsContent>
              <p className="label">CAC Reg No.</p>
              <p className="value">{sellerDetails?.cac || 'N/A'}</p>
            </OrderDetailsContent>
            <div>
              <h4 style={{ marginBottom: '5px' }}>Utility Bill</h4>
              <Zoom>
                <img
                  alt="Seller Utility Bill"
                  src={sellerDetails?.utilityBill}
                  style={{ borderRadius: '10px', maxWidth: '100%', width: '100%', cursor: 'pointer' }}
                  width="500"
                />
              </Zoom>
            </div>
            <div>
              <h4 style={{ marginBottom: '5px' }}>Government Id</h4>
              <Zoom>
                <img
                  alt="Seller Government Id"
                  src={sellerDetails?.governmentId}
                  style={{ borderRadius: '10px', maxWidth: '100%', width: '100%', cursor: 'pointer' }}
                  width="100%"
                />
              </Zoom>
            </div>

            {/* <div>
              <img src={sellerDetails?.utilityBill} />
            </div> */}
          </div>
        </Modal>
      )}

      {isConfirmModalOpen && (
        <Modal title="Seller Verification" maxWidth="400px" onModalClose={handleConfirmClose}>
          <p>Are you sure you want to verify this seller?</p>
          <div
            style={{ display: 'flex', alignContent: 'center', alignItems: 'center', gap: '10px', marginTop: '15px' }}>
            <Button onClick={handleConfirmClose} extraStyles={extraButtonStyles}>
              Cancel
            </Button>
            <Button isLoading={isLoading} onClick={handleConfirmVerify} extraStyles={extraButtonStyles}>
              Confirm
            </Button>
          </div>
        </Modal>
      )}
      {isConfirmRejectModalOpen && (
        <Modal title="Reject Seller" maxWidth="400px" onModalClose={handleConfirmRejectClose}>
          <p>Please provide a reason for rejecting this seller:</p>
          <textarea
            value={rejectReason}
            onChange={e => setRejectReason(e.target.value)}
            placeholder="Enter the reason for rejection"
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '5px',
              marginBottom: '10px',
              marginTop: '10px',
              borderColor: '#444444'
            }}
            rows={4}
          />
          <div
            style={{ display: 'flex', alignContent: 'center', alignItems: 'center', gap: '10px', marginTop: '15px' }}>
            <Button onClick={handleConfirmRejectClose} extraStyles={extraButtonStyles}>
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              onClick={handleConfirmReject} // Trigger rejection with the reason
              extraStyles={{
                color: `${COLORS.MainBlue}`,
                backgroundColor: '#EFF1FB',
                borderRadius: '12px',
                height: '31px',
                width: '102px',
                fontSize: '14px',
                fontWeight: '400'
              }}
              disabled={!rejectReason.trim()} // Disable confirm button if reason is empty
            >
              Confirm
            </Button>
          </div>
        </Modal>
      )}
    </ActionCON>
  );
};
