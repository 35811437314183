import { atom } from 'recoil';
import { STORE_KEYS } from '../keys';

export const orderFilter = atom<string>({
  key: STORE_KEYS.ORDER_FILTER,
  default: 'PENDING'
});

export const deliveryFilter = atom<string>({
  key: STORE_KEYS.DELIVERY_FILTER,
  default: 'Delivered'
});


export const actionPopover = atom<HTMLElement | null>({
  key: STORE_KEYS.ORDER_ACTION_POPOVER,
  default: null
});
