import { LogisticsAction } from '../../actions/Admin/Logistics';
import { BrokenUserIcon, BrokenUserTickIcon } from '../../assets/Icons';

export const topCardData = [
  {
    title: 'Customers',
    total: 0,
    Icon: BrokenUserIcon,
    iconBg: '#F5FBEF',
    percentage: '+3.5%'
  },
  {
    title: 'Sub Admins',
    total: 0,
    Icon: BrokenUserTickIcon,
    iconBg: '#EFF1FB',
    percentage: '+3.5%'
  },

  {
    title: 'Accountants',
    total: 0,
    Icon: BrokenUserTickIcon,
    percentage: '-3.93%',
    iconBg: '#EFF1FB'
  }
];

export const filter = [
  {
    label: 'Customers',
    value: 'All'
  },
  {
    label: 'Sub Admins',
    value: 'Sub Admins'
  },

  {
    label: 'Accountants',
    value: 'Accountants'
  }
];

export const columns = [
  {
    id: 1,
    field: 'name',
    headerName: 'Name',
    flex: 2
  },
  {
    id: 2,
    field: 'email',
    headerName: 'Email',
    flex: 2
  },
  {
    id: 3,
    field: 'successfulOrders',
    headerName: 'Successful Orders',
    flex: 1
  },
  {
    id: 4,
    field: 'rejectedOrders',
    headerName: 'Rejected Orders',
    flex: 1
  },

  {
    id: 5,
    field: 'action',
    headerName: 'Action',
    flex: 0,
    minWidth: 100,
    renderCell: ({ row }: any) => <LogisticsAction logistics={row} />
  }
];
