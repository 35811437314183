// import { Action } from '../../assets/Icons';
import { OrderStatusCON } from './style';
// import { ActionCON } from '../Product/style';
import { formatCurrency } from '../../utils/currencyFormater';
import { AdminOrderActions } from '../../actions/Admin/Order';
import { formatDate } from '../../utils/dateFormater';
import StatusDot from '../../components/atoms/StatusDot';
import { AdminCompletedOrderActions } from '../../actions/Admin/Order/Completed';
import { OrderActions } from '../../actions';

export const filter = [
  {
    label: 'All Orders',
    value: 'ALL'
  },
  {
    label: 'Pending Orders',
    value: 'PENDING'
  },
  {
    label: 'Ongoing Orders',
    value: 'ONGOING'
  },

  {
    label: 'Cancelled Orders',
    value: 'CANCELLED'
  },

  {
    label: 'Completed Orders',
    value: 'COMPLETED'
  }
];

export const newOrderColumn = [
  {
    id: 1,
    field: 'orderRef',
    headerName: 'Order ID',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>{row?.orderRef}</p>
  },

  {
    id: 2,
    field: 'name',
    headerName: "Customer's Name",
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{row?.customer?.firstname + ' ' + row?.customer?.lastname}</p>
  },
  {
    id: 3,
    field: 'vendorName',
    headerName: "Vendor's Name",
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{row?.vendorName}</p>
  },

  {
    id: 4,
    field: 'orderItemQuantity',
    headerName: 'No of Items',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>{row?.orderItemQuantity}</p>
  },
  {
    id: 5,
    field: 'orderDate',
    headerName: 'Order Date',
    flex: 2,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{formatDate(row?.orderDate)}</p>
  },

  {
    id: 6,
    field: 'price',
    headerName: 'Price',
    flex: 2,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>{formatCurrency(row?.totalAmount)}</p>
  },
  {
    id: 7,
    field: 'deliveryMethod',
    headerName: 'Delivery Type',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>{row?.deliveryMethod}</p>
  },
  {
    id: 8,
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }: any) => (
      <OrderStatusCON status={row?.orderStatus.toLowerCase()}>
        <p>{row?.orderStatus === 'cancelled' ? 'Canceled' : row?.orderStatus}</p>
      </OrderStatusCON>
    )
  },

  // {
  //   id: 7,
  //   field: 'orderType',
  //   headerName: 'Order Type',
  //   flex: 1,
  //   minWidth: 100
  // }

  {
    id: 9,
    field: 'action',
    headerName: 'Action',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => <OrderActions order={row?.id} />
  }
];

export const orderColumns = [
  {
    id: 1,
    field: 'orderRef',
    headerName: 'Order ID',
    flex: 1,
    minWidth: 200
  },

  {
    id: 2,
    field: 'name',
    headerName: "Customer's Name",
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>{row?.customer?.name}</p>
  },

  {
    id: 3,
    field: 'orderItemQuantity',
    headerName: 'No of Items',
    flex: 1,
    minWidth: 100
  },

  {
    id: 4,
    field: 'orderDate',
    headerName: 'Order Date',
    flex: 1,
    minWidth: 100
  },

  {
    id: 5,
    field: 'totalAmount',
    headerName: 'Price',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>{formatCurrency(row?.totalAmount)}</p>
  },

  {
    id: 6,
    field: 'deliveryMethod',
    headerName: 'Delivery Type',
    flex: 1,
    minWidth: 100
  },

  {
    id: 7,
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }: any) => (
      <OrderStatusCON status={row.status.toLowerCase()}>
        <StatusDot status={row.status} />
        <p>{row.status === 'cancelled' ? 'Canceled' : row.status}</p>
      </OrderStatusCON>
    )
  },

  {
    id: 8,
    field: 'action',
    headerName: 'Action',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => <AdminOrderActions status={row.status} order={row.id} />
  }
];

export const completedOrderColumn = [
  {
    id: 1,
    field: 'orderRef',
    headerName: 'Order ID',
    flex: 1,
    minWidth: 150
  },

  {
    id: 2,
    field: 'name',
    headerName: "Customer's Name",
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>{row?.customer?.name}</p>
  },

  {
    id: 3,
    field: 'orderItemQuantity',
    headerName: 'No of Items',
    flex: 1,
    minWidth: 100
  },

  {
    id: 4,
    field: 'orderDate',
    headerName: 'Order Date',
    flex: 1,
    minWidth: 100
  },

  {
    id: 5,
    field: 'deliveryMethod',
    headerName: 'Delivery Type',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => (
      <p
        style={{
          textTransform: 'capitalize'
        }}>
        {row.deliveryMethod}
      </p>
    )
  },

  {
    id: 6,
    field: 'totalAmount',
    headerName: 'Total Amount',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>{formatCurrency(row?.totalAmount)}</p>
  },

  {
    id: 4,
    field: 'timeTaken',
    headerName: 'Time Taken',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>N/A</p>
  },

  {
    id: 8,
    field: 'action',
    headerName: 'Action',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => <AdminCompletedOrderActions status={row.status} order={row.id} />
  }
];

export const orders = [
  {
    id: 1,
    key: 'name',
    noOfItems: 2,
    orderId: '#RA902282',
    name: 'Zara Bag',
    orderDate: '7/03/22, 08:00pm',
    timeTaken: '8hrs 5mins',
    deliveryType: 'Pickup',
    orderType: 'Bulk Order',
    category: 'Fashion',
    qty: '128',
    price: 'N230,000',
    status: 'Out for delivery'
  },

  {
    id: 2,
    key: 'name',
    name: 'Box of Wine',
    noOfItems: 2,
    deliveryType: 'Pickup',
    timeTaken: '8hrs 5mins',

    orderId: '#RA902282',
    orderType: 'Bulk Order',
    orderDate: '7/03/22, 08:00pm',
    category: 'Supermarket',
    qty: '128',
    price: 'N230,000',
    status: 'Confirmed',
    flex: 1,
    minWidth: 200
  },

  {
    id: 3,
    key: 'name',
    orderId: '#RA902282',
    name: 'Zara Bag',
    deliveryType: 'Pickup',
    orderType: 'Bulk Order',
    category: 'Fashion',
    timeTaken: '8hrs 5mins',

    qty: '128',
    orderDate: '7/03/22, 08:00pm',

    noOfItems: 2,
    price: 'N230,000',
    status: 'Processing',
    flex: 1,
    minWidth: 200
  },

  {
    id: 4,
    key: 'name',
    name: 'Zara Bag',
    orderId: '#RA902282',
    noOfItems: 2,
    category: 'Fashion',
    qty: '128',
    timeTaken: '8hrs 5mins',

    deliveryType: 'Pickup',
    orderDate: '7/03/22, 08:00pm',
    orderType: 'Bulk Order',
    price: 'N230,000',
    status: 'Out for delivery',
    flex: 1,
    minWidth: 200
  }
];
