// import { ChangeEvent } from 'react';
import { DashboardHeader } from '../../components/molecules';
import { BrokenAdd } from '../../assets/Icons';
import { columns } from './data';
import { useState } from 'react';
import { TableCON, TableHeaderCON } from './style';
import { Container, CustomTable, Spacer } from '../../components/atoms';
import { useQuery } from 'react-query';
import { AddCategory } from './AddCategory';
import { getCategories } from '../../network/services/admin/categories';
import { AddSubCategory } from './AddSubCategory';

export const CategoryList = () => {
  const [isAddCategory, setAddCategory] = useState<boolean>(false);
  const [isAddSubCategory, setAddSubCategory] = useState<boolean>(false);
  const { data, isFetchedAfterMount } = useQuery(['admin_categories'], getCategories);
  const categories = data?.data || [];

  const onAddCategoryModalClose = () => {
    setAddCategory(false);
  };
  const onAddCategoryModalOpen = () => {
    setAddCategory(true);
  };

  const onAddSubCategoryModalClose = () => {
    setAddSubCategory(false);
  };

  const onAddSubCategoryModalOpen = () => {
    setAddSubCategory(true);
  };

  return (
    <Container>
      <DashboardHeader
        title="Categories"
        buttonPlaceholder="Add Category"
        onClick={onAddCategoryModalOpen}
        Icon={BrokenAdd}
      />

      <Spacer height="48px" />
      <TableCON>
        <TableHeaderCON>
          <h3>Category List</h3>
        </TableHeaderCON>

        <Container>
          <CustomTable isLoading={isFetchedAfterMount} columns={columns} data={categories} />
        </Container>
      </TableCON>

      {isAddCategory && <AddCategory onModalClose={onAddCategoryModalClose} />}
      {isAddSubCategory && <AddSubCategory onModalClose={onAddSubCategoryModalClose} />}
    </Container>
  );
};
