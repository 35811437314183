import { ChangeEvent } from 'react';
import { DashboardHeader } from '../../components/molecules';
// import { DashboardHeader, Modal, TabPanel, TopHeaderCard } from '../../components/molecules';
import { BrokenAdd } from '../../assets/Icons';
import {
  // unverifiedSellerColumn,
  // verifiedSellerColumn,
  // verifiedBusinessColumn,
  // unverifiedBusinessColumn,
  // shopperColumn,
  filter
} from './data';
// import { CustomAuthTab, CustomAuthTabs } from './style';
import { useState } from 'react';
import { TableCON, TableHeaderCON } from './style';
import { FILTER_KEYS } from '../../store/keys';
import { Container, SelectField, Spacer } from '../../components/atoms';
// import { Container, CustomTable, SelectField, Spacer } from '../../components/atoms';
import { userFilter } from '../../store/atoms/userAtom';
import { useRecoilState } from 'recoil';
// import { useMediaQuery, Theme } from '@mui/material';
// import { useQuery } from 'react-query';
import { AdminUsersAnalytics } from './Analytics';
// import { getAllAdminUsers } from '../../network/services/admin/users';
import { AdminShoppers } from './shoppers';
import { AdminSellers } from './sellers';
import { AdminBusinesses } from './businesses';
import { AdminAddUser } from './AddUser';
import { AdminAdmins } from './admins';

export const UserList = () => {
  // const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [value, setValue] = useState(0);
  const [userFilterState, setuserFilterState] = useRecoilState(userFilter);
  const [addUser, setAddUser] = useState<boolean>(false);
  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setuserFilterState(e.target.value);
  };

  // const { data, isFetchedAfterMount } = useQuery(['admin_users'], getAllAdminUsers);
  // const _users = data || [];

  const onAddUserModalClose = () => {
    setAddUser(false);
  };
  const onAddUserModalOpen = () => {
    setAddUser(true);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { SELLERS, BUSINESSES, SHOPPERS, ADMINS } = FILTER_KEYS;
  return (
    <Container>
      {userFilterState.includes('Shoppers') ? (
        <DashboardHeader title="Users"  onClick={onAddUserModalOpen} Icon={BrokenAdd} />
      ) : (
        <DashboardHeader title="Users" />
      )}

      <AdminUsersAnalytics />

      <Spacer height="48px" />
      <TableCON>
        <TableHeaderCON>
          {userFilterState.includes(SELLERS) ? (
            <h3>Sellers</h3>
          ) : userFilterState.includes(SHOPPERS) ? (
            <h3>Shoppers</h3>
          ) : userFilterState.includes(ADMINS) ? (
            <h3>Admins</h3>
          ) : (
            userFilterState.includes(BUSINESSES) && <h3>Businesses</h3>
          )}

          <SelectField handleChange={handleFilterChange} options={filter} value={userFilterState} />
        </TableHeaderCON>
        {userFilterState.includes(SELLERS) ? (
          <AdminSellers value={value} handleTabChange={handleTabChange} />
        ) : userFilterState.includes(SHOPPERS) ? (
          <AdminShoppers />
        ) : userFilterState.includes(ADMINS) ? (
          <AdminAdmins />
        ) : (
          <AdminBusinesses value={value} handleTabChange={handleTabChange} />
        )}
      </TableCON>

      {addUser && <AdminAddUser onModalClose={onAddUserModalClose} />}
    </Container>
  );
};
