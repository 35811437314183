import React, { useState } from 'react';
import { DashboardCON, DashboardTab, DashboardTabs, DashboardTableCON, TopContentCON, ChartsCON } from './style';
import { BoxBlueIcon, BoxOrangeIcon, CloudIcon, GraphIcon } from '../../assets/Icons';
// import { CloudIcon, UploadIcon } from '../../assets/Icons';
import { CustomTable, Spacer } from '../../components/atoms';
// import { Button, CustomTable, Spacer } from '../../components/atoms';
import { TabPanel, TopHeaderCard } from '../../components/molecules';
import { AdminUsersIcon } from '../../assets/Icons';
// import { BrokenUserIcon, BrokenUserTickIcon, AdminUsersIcon } from "../../assets/Icons";
import { a11yProps } from './data';
import { newOrderColumn } from '../Order/data';
import { Theme, useMediaQuery } from '@mui/material';
import { useQuery } from 'react-query';
import { getAdminOverviewAnalytics } from '../../network/services/admin/overview';
import { calculatePercentage } from '../../utils/percentage';
import { getAdminOrders } from '../../network/services/admin/order';
import SalesReportChart from '../../components/molecules/Charts/SalesReportChart';
import TotalProfitsChart from '../../components/molecules/Charts/TotalProfitsChart';

export const Dashboard = () => {
  const [value, setValue] = useState(0);

  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState(1);
  const pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE ? parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE) : 10;
  const { data, isFetchedAfterMount, isLoading } = useQuery(
    ['admin_orders', { page: page - 1, size: pageSize, type: 'ALL' }],
    getAdminOrders,
    {
      staleTime: Infinity
    }
  );

  const orders = data?.data || [];

  const { data: analyticsData } = useQuery(['admin_overview_analytics'], getAdminOverviewAnalytics);
  const analytics = analyticsData?.data || {};

  const topCardData = [
    {
      title: 'Total Sellers',
      total: analytics?.sellers ?? 0,
      Icon: AdminUsersIcon,
      iconBg: '#EFF1FB',
      percentage: analytics?.sellers ? calculatePercentage(analytics?.sellers) : '0%'
    },
    {
      title: 'Total Businesses',
      total: analytics?.business ?? 0,
      Icon: AdminUsersIcon,
      iconBg: '#EFF1FB',
      percentage: analytics?.business ? calculatePercentage(analytics?.business) : '0%'
    },

    {
      title: 'Total Sales',
      total: analytics?.totalSales ?? 0,
      Icon: GraphIcon,
      percentage: analytics?.totalSales ? calculatePercentage(analytics?.totalSales) : '0%',
      iconBg: '#F5FBEF'
    },

    {
      title: 'Total Orders',
      total: analytics?.totalOrders ?? 0,
      percentage: analytics?.totalOrders ? calculatePercentage(analytics.totalOrders) : '0%',
      Icon: BoxOrangeIcon,
      iconBg: '#FEF9EC'
    },

    {
      title: 'Total Customers',
      total: analytics?.totalCustomers ?? 0,
      percentage: analytics?.totalCustomers ? calculatePercentage(analytics?.totalCustomers) : '0%',
      Icon: AdminUsersIcon,
      iconBg: '#EFF1FB'
    },

    {
      title: 'Cart Abandonment',
      total: analytics?.cartAbandonment ?? 0,
      percentage: analytics?.cartAbandonment ? calculatePercentage(analytics.cartAbandonment) : '0%',
      Icon: BoxBlueIcon,
      iconBg: '#0095FF1F'
    },
    {
      title: 'Orders Returned',
      total: 0,
      // percentage: calculatePercentage(analytics?.totalOrders),
      Icon: BoxBlueIcon,
      iconBg: '#0095FF1F'
    }
    // {
    //   title: 'Products Not Found',
    //   total: 0,
    //   percentage: '-26.3%',
    //   Icon: BrokenUserTickIcon,
    //   iconBg: '#EFF1FB'
    // }
  ];

  return (
    <DashboardCON>
      <TopContentCON>
        <div>
          <h3>Overview</h3>
          <p>
            Good morning, Admin <CloudIcon />
          </p>
        </div>
        {/* <div>
          <Button>
            Export Report as CSV
            <UploadIcon />
          </Button>
        </div> */}
      </TopContentCON>

      {!md && (
        <div>
          <TopHeaderCard data={topCardData.slice(0, 4)} />
          <TopHeaderCard gap="40px" data={topCardData.slice(4, 7)} />
        </div>
      )}

      {md && <TopHeaderCard data={topCardData} />}

      <Spacer height="24px" />

      <ChartsCON>
        {!md ? (
          <>
            <SalesReportChart width="50rem" />
            <TotalProfitsChart width="30rem" />
          </>
        ) : (
          <>
            <SalesReportChart height="250" />
            <TotalProfitsChart />
          </>
        )}
      </ChartsCON>

      <Spacer height="24px" />

      <DashboardTableCON>
        <DashboardTabs disableRipple={true} value={value} onChange={handleTabChange}>
          <DashboardTab label="Recent Orders" {...a11yProps(0)} />
          {/* <DashboardTab label="Recent Orders" {...a11yProps(1)} /> */}
        </DashboardTabs>
        <TabPanel index={0} value={value}>
          <CustomTable
            columns={newOrderColumn}
            data={orders}
            isLoading={isLoading}
            totalItems={data?.totalElements ?? 0}
            totalPages={data?.totalPages ?? 0}
            currentPage={page}
            setCurrentPage={setPage}
          />
        </TabPanel>
        {/* <TabPanel index={1} value={value}>
          <CustomTable columns={newOrderColumn} data={orders.slice(0, 5)} />
        </TabPanel> */}
      </DashboardTableCON>
    </DashboardCON>
  );
};
