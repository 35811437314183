import { GreenDot, OrangeDot } from '../../assets/Icons';
import { convertInformalStringToDate } from '../../utils/dateFormater';
import { SubscriptionStatusCON } from './style';

export const columns = [
  {
    id: 1,
    field: 'subscriber',
    headerName: 'Subscriber',
    flex: 1,
    minWidth: 200
  },

  {
    id: 2,
    field: 'packageName',
    headerName: 'Package Name',
    flex: 2,
    minWidth: 200
  },

  {
    id: 3,
    field: 'subscriptionAmount',
    headerName: 'Subscription Amount',
    flex: 2,
    minWidth: 200
  },

  {
    id: 4,
    field: 'nextBilling',
    headerName: 'Next Billing',
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{convertInformalStringToDate(row?.nextBilling)} </p>
  },

  {
    id: 5,
    field: 'lastBilling',
    headerName: 'Last Billing',
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{convertInformalStringToDate(row?.lastBilling)} </p>
  },
  {
    id: 6,
    field: 'status',
    headerName: 'status',
    flex: 2,
    minWidth: 150,
    renderCell: ({ row }: any) => (
      <SubscriptionStatusCON status={row.status}>
        {row.status.toLowerCase().includes('expired') ? <OrangeDot /> : <GreenDot />}
        <p>{row.status}</p>
      </SubscriptionStatusCON>
    )
  }
];
