import { Button } from '../../../components/atoms';
import { Spacer } from '../../../components/atoms';
import { Modal } from '../../../components/molecules';

type ConfirmationModalProps = {
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmationModal = ({ onConfirm, onCancel }: ConfirmationModalProps) => {
  return (
    <Modal title="Confirm Action" maxWidth="400px" onModalClose={onCancel}>
      <p>Are you sure you want to add this category?</p>
      <Spacer height="24px" />
      <Button onClick={onConfirm}>Yes, Add Category</Button>
      <Spacer height="12px" />
      <Button onClick={onCancel}>Cancel</Button>
    </Modal>
  );
};
