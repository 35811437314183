import { DashboardHeader, TopHeaderCard } from '../../components/molecules';

import { columns, filter } from './data';
import { DeliveryCON, DeliveryTableCON, TableHeaderCON } from './style';
import { CustomTable, SelectField, Spacer } from '../../components/atoms';
import { useQuery } from 'react-query';
import { getAdminDeliveries, getAdminDeliveriesAnalytics } from '../../network/services/admin/deliveries';
import { CheckIcon, MessageIcon } from '../../assets/Icons';
import { ChangeEvent } from 'react';
import { useRecoilState } from 'recoil';
import { deliveryFilter } from '../../store/atoms/orderAtom';
import { FILTER_KEYS } from '../../store/keys';

export const Delivery = () => {
  const [deliveryFilterState, setDeliveryFilterState] = useRecoilState(deliveryFilter);
  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDeliveryFilterState(e.target.value);
  };
  const { data, isFetchedAfterMount } = useQuery(['admin_deliveries'], getAdminDeliveries);
  const deliveries = data?.data || [];

  const filteredDeliveries = deliveries.filter((delivery: any) => delivery.id != null);

  const { data: analyticsData } = useQuery(['admin_delivery'], getAdminDeliveriesAnalytics);
  const analytics = analyticsData?.data || {};

  const pendingDeliveries = filteredDeliveries.filter((delivery: { status: string }) => delivery.status === 'pending');
  const processedDeliveries = filteredDeliveries.filter(
    (delivery: { status: string }) => delivery.status !== 'pending'
  );

  const topCardData = [
    {
      title: 'All',
      total: analytics?.all,
      Icon: CheckIcon,
      iconBg: '#F5FBEF'
    },
    {
      title: 'Delivered',
      total: analytics?.completed,
      Icon: CheckIcon,
      iconBg: '#F5FBEF'
    },
    {
      title: 'Pending Deliveries',
      total: analytics?.pending,
      Icon: MessageIcon,
      iconBg: '#FFFCF0'
    }
  ];

  const { DELIVERED_ORDERS } = FILTER_KEYS;

  return (
    <DeliveryCON>
      <DashboardHeader title="Deliveries" />
      <TopHeaderCard gap="60px" data={topCardData} />
      <Spacer height="48px" />
      <DeliveryTableCON>
        <TableHeaderCON>
          {deliveryFilterState.includes(DELIVERED_ORDERS) ? <h3>Delivered</h3> : <h3>Pending Deliveries</h3>}
          <SelectField handleChange={handleFilterChange} options={filter} value={deliveryFilterState} />
        </TableHeaderCON>
        {deliveryFilterState.includes(DELIVERED_ORDERS) ? (
          <CustomTable isLoading={isFetchedAfterMount} columns={columns} data={processedDeliveries} />
        ) : (
          <CustomTable isLoading={isFetchedAfterMount} columns={columns} data={pendingDeliveries} />
        )}
      </DeliveryTableCON>
    </DeliveryCON>
  );
};
