import { useState } from 'react';
import { useQuery } from 'react-query';
import { Container, CustomTable } from '../../components/atoms';
import { TabPanel } from '../../components/molecules';
import { getSellers } from '../../network/services/admin/users';
// import { getAllAdminUsers, getSellers } from "../../network/services/admin/users";
import { unverifiedSellerColumn, verifiedSellerColumn } from './data';
import { CustomAuthTab, CustomAuthTabs } from './style';
type AdminUsersProps = {
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  value: number;
};

export const AdminSellers = ({ handleTabChange, value }: AdminUsersProps) => {
  const [page, setPage] = useState(1);
  const pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE ? parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE) : 10;
  const { data, isFetchedAfterMount } = useQuery(['admin_sellers', { page: page - 1, size: pageSize }], getSellers);
  const sellers = data?.data || [];

  const verifiedSellers = sellers.filter((seller: { status: boolean; business_approval: string }) => {
    if (seller.status === true && seller.business_approval === 'approved') {
      return seller;
    } else {
      return null;
    }
  });

  // const unverifiedSellers = sellers.filter((seller: { verified: boolean }) => seller.verified === false);
  const unverifiedSellers = sellers.filter((seller: { status: boolean; business_approval: string }) => {
    if (seller.status === false && seller.business_approval === 'pending') {
      return seller;
    } else {
      return null;
    }
  });

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  return (
    <Container>
      <CustomAuthTabs value={value} onChange={handleTabChange}>
        <CustomAuthTab label="Verified" {...a11yProps(0)} />
        <CustomAuthTab label="Unverified" {...a11yProps(1)} />
      </CustomAuthTabs>
      <TabPanel index={0} value={value}>
        <CustomTable
          isLoading={isFetchedAfterMount}
          columns={verifiedSellerColumn}
          data={verifiedSellers}
          getRowId={row => row.vendorId}
          totalItems={data?.totalElements ?? 0}
          totalPages={data?.totalPages ?? 0}
          currentPage={page}
          setCurrentPage={setPage}
        />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <CustomTable
          isLoading={isFetchedAfterMount}
          columns={unverifiedSellerColumn}
          data={unverifiedSellers}
          getRowId={row => row.vendorId}
          totalItems={data?.totalElements ?? 0}
          totalPages={data?.totalPages ?? 0}
          currentPage={page}
          setCurrentPage={setPage}
        />
      </TabPanel>
    </Container>
  );
};
