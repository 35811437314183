/* eslint-disable @typescript-eslint/no-unused-vars */
import { CancelOrder, OrderDetailsResponse, ResponseSuccess } from '../../../TYPES/api.body';
import {
  ADMIN_CANCEL_ORDER,
  ADMIN_GET_ORDER_DETAILS,
  ADMIN_TRACK_STATUS,
  ADMIN_GET_ALL_ORDERS,
  GET_ADMIN_ORDER_ANALYTICS,
  GET_ADMIN_ORDER_DETAILS,
  GET_ADMIN_ORDER_STATISTICS,
  GET_ADMIN_SALES_STATISTICS,
  GET_ADMIN_TOTAL_PROFITS_STATISTICS
} from '../../config/endpoints';
import api from '../../config/setAuthHeader';

export const getAdminOrders = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { page, size, type = 'ALL' } = payload;

  const { data } = await api.get(`${ADMIN_GET_ALL_ORDERS}${type}?page=${page}&size=${size}&sort=id,desc`);
  return data;
};

export const getAdminOrdersAnalytics = async () => {
  const { data } = await api.get(`${GET_ADMIN_ORDER_ANALYTICS}`);
  return data;
};

export const getOrderDetails = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { id } = payload;
  const { data } = await api.get(`${GET_ADMIN_ORDER_DETAILS}/${id}`);
  return data;
};

export const adminCancelOrder = async (formData: CancelOrder) => {
  const { orderId } = formData;
  const { data } = await api.post(`${ADMIN_CANCEL_ORDER}${orderId}/reject_order`, formData);
  return data;
};

export const adminTrackStatus = async (orderId: any, status: string) => {
  const { data } = await api.get(`${ADMIN_TRACK_STATUS}/${orderId}/${status}`);
  return data;
};

export const adminOrdersStatistics = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { exportDate } = payload;
  const { data } = await api.get(`${GET_ADMIN_ORDER_STATISTICS}?dateTime=${exportDate}`);
  return data;
};

export const adminSalesStatistics = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { exportDate } = payload;
  const { data } = await api.get(`${GET_ADMIN_SALES_STATISTICS}?dateTime=${exportDate}`);
  return data;
};

export const adminTotalProfitsStatistics = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { exportDate } = payload;
  const { data } = await api.get(`${GET_ADMIN_TOTAL_PROFITS_STATISTICS}?dateTime=${exportDate}`);
  return data;
};

export const adminGetOrderDetails = async ({ queryKey }: any): Promise<ResponseSuccess<OrderDetailsResponse>> => {
  const [_, orderRef] = queryKey;
  const { data } = await api.get(`${ADMIN_GET_ORDER_DETAILS}/${orderRef}`);
  return data;
};
