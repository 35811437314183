import { useRecoilState } from 'recoil';
import { Action, BrokenEyeIcon } from '../../assets/Icons';
import { CustomPopover } from '../../components/atoms';
import { actionPopover } from '../../store/atoms/orderAtom';
import { ActionCON, OrderDetailsCON, OrderDetailsContent, StatusCON } from '../style';
import { Modal } from '../../components/molecules/Modal/Modal';
import { viewOrderModal } from '../../store/atoms/modalAtoms';
import { useQuery } from 'react-query';
import { getOrderDetails } from '../../network/services/admin/order';
import { format } from 'date-fns';
import { formatCurrency } from '../../utils/currencyFormater';

export const OrderActions = ({ order }: any) => {
  const [value, setValue] = useRecoilState(actionPopover);
  const [isOpen, setIsOpen] = useRecoilState(viewOrderModal);

  const { data, refetch } = useQuery(['admin_Order_details', { id: order }], getOrderDetails, {
    enabled: false // Prevents the query from running initially
  });
  const orderDetails = data?.data || {};

  const handleOpen = (event: any) => {
    if (value != null) {
      setValue(null);
    } else {
      setValue(event.currentTarget);
    }
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
    refetch(); // Manually triggers the query when the modal opens
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, 'MM/dd/yy hh:mm a');
  };

  const options = [
    {
      label: 'View details',
      value: 'View details',
      Icon: BrokenEyeIcon,
      handleAction: handleOpenModal
    }
  ];

  return (
    <ActionCON aria-describedby="order-actions" onClick={event => handleOpen(event)}>
      <Action />
      <CustomPopover item={order} id="order-actions" open={value} options={options} />

      {isOpen && (
        <Modal title="Order Details" maxWidth="500px" onModalClose={handleModalClose}>
          <OrderDetailsCON>
            <OrderDetailsContent>
              <p className="label">Order ID</p>
              <p className="value">{orderDetails?.orderRef || 'N/A'}</p>
            </OrderDetailsContent>
            <OrderDetailsContent>
              <p className="label">Customer's Name</p>
              <p className="value">
                {orderDetails?.customer?.firstname + ' ' + orderDetails?.customer?.lastname || 'N/A'}
              </p>
            </OrderDetailsContent>
            <OrderDetailsContent>
              <p className="label">Customer's Email</p>
              <p className="value">{orderDetails?.customer?.email || 'N/A'}</p>
            </OrderDetailsContent>
            <OrderDetailsContent>
              <p className="label">Customer's Phone Number</p>
              <p className="value">{orderDetails?.customer?.phoneNumber || 'N/A'}</p>
            </OrderDetailsContent>
            <OrderDetailsContent>
              <p className="label">Customer Type</p>
              <p className="value">{orderDetails?.customer?.customer_type || 'N/A'}</p>
            </OrderDetailsContent>
            <OrderDetailsContent>
              <p className="label">Vendor's Name</p>
              <p className="value">{orderDetails?.vendorName || 'N/A'}</p>
            </OrderDetailsContent>
            <OrderDetailsContent>
              <p className="label">Number of Item(s)</p>
              <p className="value">{orderDetails?.orderItemQuantity || 'N/A'}</p>
            </OrderDetailsContent>
            <OrderDetailsContent>
              <p className="label">Total Amount</p>
              <p className="value">{formatCurrency(orderDetails?.totalAmount) || 'N/A'}</p>
            </OrderDetailsContent>
            <OrderDetailsContent>
              <p className="label">Order Date</p>
              <p className="value">{(orderDetails?.orderDate && formatDate(orderDetails?.orderDate)) || 'N/A'}</p>
            </OrderDetailsContent>
            <OrderDetailsContent>
              <p className="label">Delivery Type</p>
              <p className="value">{orderDetails?.deliveryMethod || 'N/A'}</p>
            </OrderDetailsContent>
            <OrderDetailsContent>
              <p className="label"> Status</p>
              <StatusCON status={orderDetails?.orderStatus?.toLowerCase() || 'pending'}>
                <p>{orderDetails?.orderStatus || 'Pending'}</p>
              </StatusCON>
            </OrderDetailsContent>
          </OrderDetailsCON>
        </Modal>
      )}
    </ActionCON>
  );
};
