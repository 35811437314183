import { ChangeEvent, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { HidePassword, ShowPassword } from '../../assets/Icons';
import { Button, Container, Spacer } from '../../components/atoms';
import { InputField } from '../../components/atoms/InputField/InputField';
import { useNavigate } from 'react-router-dom';
import { useMutationHook } from '../../network/mutations/useMutationHook';
import { validators } from '../../utils/validator';
import { AuthContainer, ContainerExtraStyles, extraStylesInput, InputCon, TogglePassword } from './styles';
import { useSetRecoilState } from 'recoil';
import { userIsLoggedIn, userRoleAtom } from '../../store/atoms/userAtom';
import { adminLogin } from '../../network/services/admin/auth';

const INITIALSTATE = {
  email: '',
  password: ''
};

const Login = () => {
  const [formData, setFormData] = useState(INITIALSTATE);
  const [formError, setFormError] = useState(INITIALSTATE);
  const onUpdateFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validators[name](value);
    setFormData(formData => ({ ...formData, [name]: value }));
    setFormError({
      ...formError,
      [name]: error
    });
  };

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<'text' | 'password'>('password');

  const onTogglePassword = () => {
    if (showPassword === 'text') return setShowPassword('password');
    setShowPassword('text');
  };

  const setUserStatus = useSetRecoilState(userIsLoggedIn);
  const setUserRole = useSetRecoilState(userRoleAtom);
  const { mutateAsync, isLoading } = useMutationHook(adminLogin, 'LOGIN');
  const _login = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    try {
      const { data } = await mutateAsync(formData);
      if (data.description === 'Account not verified') {
        navigate(`/otp/${formData.email}`);
      }

      if (data.status === 'success') {
        sessionStorage.setItem('access_token', data.access_token);
        sessionStorage.setItem('refresh_token', data.refresh_token);
        sessionStorage.setItem('is_login', 'true');
        setUserStatus(true);
        setUserRole('admin');
        navigate('/admin/dashboard');
      }
    } catch (error: any) {}
  };

  // const disabled = !formData.email || !formData.password || !!validators.email(formData.email);

  return (
    <AuthContainer>
      <div>
        <Spacer height="4rem" />

        <h1> Admin Login</h1>

        <Container extraStyles={ContainerExtraStyles}>
          <form className="sign-up">
            <Spacer height="1.5rem" />
            <InputCon>
              <span>Email Address</span>
              <InputField
                type="email"
                name="email"
                onChange={onUpdateFormData}
                placeholder="example@mail.com"
                extraStyles={extraStylesInput}
                required={true}
                error={formError.email}
                autoComplete="username"
              />
            </InputCon>

            <Spacer height="1.5rem" />
            <InputCon>
              <span>Password</span>
              <InputField
                type={showPassword}
                name="password"
                onChange={onUpdateFormData}
                placeholder="password"
                extraStyles={extraStylesInput}
                // required={true}
                // error={formError.password}
                action={
                  <TogglePassword onClick={onTogglePassword}>
                    {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
                  </TogglePassword>
                }
                autoComplete="new-passowrd"
              />
            </InputCon>

            <Spacer height="2rem" />

            <div className="buttonContainer">
              <Button isLoading={isLoading} type="submit" onClick={_login}>
                Log in
              </Button>
            </div>
          </form>
        </Container>
      </div>
    </AuthContainer>
  );
};

export default Login;
